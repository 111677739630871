// some-inner-component.jsx
import { React } from 'react';
import { useSwiper } from 'swiper/react';

export default function SwiperNextButton(props) {
  const swiper = useSwiper();

  return (
    <button className="buttonOrange" onClick={() => swiper.slideNext()}>{props.content}</button>
  );
}