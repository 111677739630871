import Badge from "../Badge";

const StartScreen = ({ onPromiseMade }) => {

  return (
    <div>
      <br />
      <button
        className="buttonBigCircle pulsating"
        onClick={onPromiseMade}
      >
        <span>Tip 1</span>
        <br/>
        <Badge text="Beginner 💪" />
        <br/>
      </button>
      <h2>
      (72 seconden om <br/>hem toe te passen)
      </h2>
    </div>
  );
};

export default StartScreen;
