import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig = {
  apiKey: "AIzaSyCLFxadH5O8pRLi-yVEq4vKmVQhuFiMHqA",
  authDomain: "app.kasza.be",
  databaseURL: "https://kasza-9f0a5-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "kasza-9f0a5",
  storageBucket: "kasza-9f0a5.appspot.com",
  messagingSenderId: "733504238872",
  appId: "1:733504238872:web:08b76d1c890e25efa85a6d",
  measurementId: "G-JCJ60WTTWX"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, 'europe-west1');
const remoteConfigInstance = getRemoteConfig(app);
remoteConfigInstance.settings.minimumFetchIntervalMillis = 30; // 1 hour
remoteConfigInstance.defaultConfig = {
  "forceHomescreenInstall": true
};


export { app, auth, db, functions, remoteConfigInstance };
