import { useNavigate, useLocation } from 'react-router-dom';
import { useUserContext } from "../context/userContext";

function BackButton() {
  const { logoutUser } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  let currentRoute = location.pathname;

  const backClick = () => {
    const currentRoute = navigate.location.pathname;
    let targetRoute = '/dashboard';
  
    switch (currentRoute) {
      case '/premium':
        targetRoute = '/linked';
        break;
      case '/dashboard/picker':
        targetRoute = '/dashboard/warning';
        break;
      case '/dashboard':
        if (window.confirm('Weet je zeker dat je wilt uitloggen?')) {
            logoutUser();
            targetRoute = '/login';
        }
        break;
      case '/improvements/:id':
        targetRoute = '/improvements';
        break;
      // Add more cases as needed
      default:
        targetRoute = '/dashboard';
        break;
    }
  
    navigate(targetRoute);
  };
  
  
  if(currentRoute === '/login'){
    return;
  }

  return (
    <div className="backButton" onClick={backClick}>
        ← 
    </div>
  );
}

export default BackButton;
