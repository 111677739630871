import React from "react";
import AddToHomescreen from "../components/AddToHomescreen";
import { remoteConfigInstance } from "../firebase";
import { fetchAndActivate } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";

/* global Tawk_API */

function InstallPrompt() {
  const [isInstalled, setIsInstalled] = React.useState(false);
  const [isIos, setIsIos] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [isNonChromiumAndroid, setIsNonChromiumAndroid] = React.useState(false);

  React.useEffect(() => {


    fetchAndActivate(remoteConfigInstance)
    .then(() => {
      const forceHomescreenInstall = getValue(remoteConfigInstance, "forceHomescreenInstall");
      console.log(forceHomescreenInstall._value);
      if(!forceHomescreenInstall._value){
        setIsInstalled(true);
      }
    })
    .catch((err) => {
      console.log(err);
    });

    
    // Check if app is running in standalone mode on iOS
    if (window.navigator.standalone) {
      setIsInstalled(true);
    } 

    // Check if app is running in standalone mode on Android
    const isRunningStandaloneAndroid = window.matchMedia("(display-mode: standalone)").matches;
    if (isRunningStandaloneAndroid) {
      setIsInstalled(true);
    }

    // Check if app is running in standalone mode on other browsers
    if (
      document.fullscreenElement ||
      window.matchMedia('(display-mode: fullscreen)').matches
    ) {
      setIsInstalled(true);
    }

    const isRunningStandalone = window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
    if (isRunningStandalone) {
      setIsInstalled(true);
    }

    // Check if user is on iOS
    if (window.navigator.userAgent.match(/(iPhone|iPod|iPad)/)) {
      setIsIos(true);
    } else {
      setIsIos(false);
    }

    // Check if user is on a mobile device
    if (window.navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    // Check if the device is running a non-Chromium-based browser on Android
    const isNonChromiumAndroid = /Android/.test(navigator.userAgent) && !(/Chrome|OPR|Edge/.test(navigator.userAgent));
    setIsNonChromiumAndroid(isNonChromiumAndroid);
  }, [isIos]);

  const openTawkChat = () => {
    if (typeof Tawk_API !== 'undefined') {
      Tawk_API.maximize(); // Open the chat window
      setIsMobile(false);
    }
  };


  // Render component only if app is not installed and user is on a mobile device
  if (isInstalled || !isMobile) {
    return null;
  } else {
    return (
      <div
        style={{
          position: "fixed",
          zIndex: 9999,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="installPopup">
          <img className="installLogo" src="logo_small.png" alt="kasza klein logo" />
          <h1>Zet Kasza op je gsm 🚀</h1>
          <br/>

          {isIos ? (
            <h2>
              Klik op <img className="installImage" src="ios_install_1.png" alt="safari share icon"/> en dan op<br/>
              <i className="installInstruction">voeg toe aan beginscherm</i>, <br/>klaar! 
            </h2>
          ) : isNonChromiumAndroid ? (
            <h2>
              Klik op <img className="installImage" src="android_install_1.png"  alt="android 3 dot menu icon"/> en dan op<br/>
              <i className="installInstruction">voeg toe aan beginscherm</i>, <br/>klaar! 
            </h2>
          ) : (
            <AddToHomescreen />
          )}

          <br/>
          <button className="buttonGrey small" onClick={openTawkChat}>Het lukt niet 💥</button>
        </div>
      </div>
    );
  }
}

export default InstallPrompt