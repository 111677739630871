import React, { useRef } from "react";
import { useUserContext } from "../context/userContext";

const Signin = () => {
  const emailRef = useRef();
  const psdRef = useRef();
  const { signInUser } = useUserContext();

  const onSubmit = (e) => {
    e.preventDefault();
    const email = emailRef.current.value;
    const password = psdRef.current.value;
    if (email && password) {
      signInUser(email, password)
    } else {
      alert('Geen email en / of wachtwoord')
    };
  };

  /*
  const forgotPasswordHandler = () => {
    const email = emailRef.current.value;
    if (email)
      forgotPassword(email).then(() => {
        emailRef.current.value = "";
      });
  };
  */

  return (
    <div>
      <form onSubmit={onSubmit}>
        <input placeholder="Email" type="email" ref={emailRef} />
        <input placeholder="Wachtwoord" type="password" ref={psdRef} />
        <button type="submit" className="buttonOrange">Inloggen</button>
      </form>
      <h3> - of - </h3>
    </div>
  );
};

export default Signin;
