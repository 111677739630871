import React, { useState } from "react";

function AddToHomescreen() {
  const [installPromptEvent, setInstallPromptEvent] = useState(null);

  React.useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setInstallPromptEvent(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, []);

  const handleAddToHomescreenClick = async () => {
    if (!installPromptEvent) return;
    installPromptEvent.prompt();

    const choiceResult = await installPromptEvent.userChoice;
    if (choiceResult.outcome === "accepted") {
      setInstallPromptEvent(null);
    }
  };

  return (
    <div>
      <button className="buttonOrange" onClick={handleAddToHomescreenClick} disabled={!installPromptEvent}>
        Installeer
      </button>
      <h2><i>(Voeg toe aan beginscherm)</i></h2>
    </div>
  );
}

export default AddToHomescreen;