import { useUserContext } from "../context/userContext";
import LinkedBankAccounts from "../components/LinkedBankAccounts";

function ProfileBox() {
	const { user } = useUserContext();
	console.log(user);
  return (
     <div className="profileBox">
	     <div className="userInfo">
		    <img className="profilePicture" alt="profile of a cat" src="http://placekitten.com/130/130" />
		    {user && (
		    <div className="naming">
		      <h1>{user.displayName}</h1>
		      {user.email}
		    </div>
			)}
	    </div>
		<LinkedBankAccounts />
	 </div>  
  );
}

export default ProfileBox;