import React from "react";
import { db } from "../firebase";
import { collection, query, where, onSnapshot  } from "firebase/firestore";
import { useUserContext } from "../context/userContext";
import NordigenJson from '../assets/nordigen';

const LinkedBankAccounts = () => {
  const { user } = useUserContext();

  const [accounts, setAccounts] = React.useState([]);

  React.useEffect(() => {
    if(user){
      const accountsRef = collection(db, "accounts");
      const accountsQuery = query(accountsRef, where("uid", "==", user.uid));
      const unsubscribe = onSnapshot(accountsQuery, (querySnapshot) => {
        console.log(querySnapshot);
        const newAccounts = [];
        querySnapshot.forEach((doc) => {
          console.log(doc);
          newAccounts.push({ id: doc.id, ...doc.data() });
        });
        setAccounts(newAccounts);
      });

      return () => unsubscribe();
    }
  }, [user]);

  function getBankJson(institution_id, amount){
  	console.log(institution_id);
  	const item = NordigenJson.filter(res=>res.id === institution_id)[0];
  	console.log(item);
  	return <div className="bankAccount" id={`${item.id}`} key={item.id}>
      <img alt="bank logo" className="bankLogo" src={item.logo} />
      <div className="naming bankNaming"><strong>{item.name}</strong> | {amount}</div>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  }

  if(accounts.length > 0){
	  return (
	        <div className="linkedAccounts">
            <h5>Gelinkte rekeningen</h5>
	          {accounts.map((account) => (
	          	getBankJson(account.institution_id, account.accounts.length)
	          ))}
	        </div>
	  );
  }
};

export default LinkedBankAccounts;
