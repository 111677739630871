import React from "react";
import NordigenJson from '../assets/nordigen';
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

const endUserAgreement_v3 = httpsCallable(functions, 'endUserAgreement_v3');

function LoadingOverlay({ children }) {
  return (
    <div className="loading-overlay">
      <div className="loading-message">{children}</div>
    </div>
  );
}


function DashboardPicker() {

  const [filter, setFilter] = React.useState('');
  let [reqLoading, setReqLoading] = React.useState(false);

  function handleItemClick(bankId){
    console.log("Chose bank: "+ bankId);
    getData(bankId);
  }

  async function getData(bankId){
    setReqLoading(<h4>Laden ... <br/><i>Er gaat zo dadelijk een nieuw scherm open</i></h4>);
    let urlBase = window.location.protocol + "//" + window.location.hostname;
    if (window.location.port) {
      urlBase += ":" + window.location.port;
    }
    console.log(urlBase);
      endUserAgreement_v3({ "bank": bankId, "redirect": urlBase })
        .then((result) => {
          const newWindow = window.open(result.data.link, '_blank', 'noopener');
          if (!newWindow || newWindow.closed || typeof newWindow.closed=='undefined') {
            setReqLoading(<h4>Laden ... <br/><i>Opent er niets? Klik dan <a target="_blank" rel="noreferrer" href={result.data.link}>hier</a> </i></h4>);
          }
        })
        .catch((error) => {
          setReqLoading(<h4>Er ging iets mis💥<br/><i>Stuur ons een berichtje via de chat of naar hoi@kasza.be!</i></h4>);
          console.log("Error :" + JSON.stringify(error))
        });
    };
  

  const listItems = NordigenJson.filter(item => item.name.toLowerCase().includes(filter.toLowerCase()) || filter === '').map((item) =>
    <div className="bankOption" id={`${item.id}`} key={item.id} onClick={() => handleItemClick(item.id)}>
      <img alt="bank logo" className="bankLogo" src={item.logo} />
      <h3><strong>{item.name}</strong></h3>
    </div>
  );
  
  return (
    <div>
    {reqLoading && (
        <LoadingOverlay>
          {reqLoading}
        </LoadingOverlay>
      )}
      <input value={filter} onChange={event => setFilter(event.target.value)} id="bankSearch" className="bankSearch" placeholder="Zoeken" type="text"/>
      <div className="bankBox">
        <ul>{listItems}</ul>
      </div>
    </div>  
  );
}

export default DashboardPicker;